import React, { ChangeEvent, MouseEvent, useEffect, useRef } from 'react';
import Icon from '../Icon';
import { FlagsList } from '../Icon/flags/enum';
import { IconsList } from '../Icon/icons/enum';
import { colors } from '../theme';
import Typography from '../Typography';
import './index.css';

interface IPill {
  title?: string;
  name: string;
  disabled?: boolean;
  checked?: boolean;
  icon?: FlagsList | IconsList;
  radio?: boolean;
  keepFlag?: boolean;
  className?: string;
  clip?: boolean;
  shadow?: boolean;
  customBorderColor?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

const Pill = ({
  title,
  name,
  icon,
  checked,
  radio,
  disabled,
  keepFlag,
  className,
  clip,
  shadow,
  customBorderColor,
  onChange,
  onClick,
}: IPill) => {
  const ref = useRef<HTMLDivElement>(null);
  const PillIcon = () => {
    if ((icon && !checked) || (icon && keepFlag)) {
      return <Icon name={icon} width={20} disabled={disabled} />;
    }

    if (checked) {
      return (
        <Icon
          name="Check"
          type="sm"
          color={colors.base.white}
          width={12}
          height={12}
        />
      );
    }

    return null;
  };

  if (!name) {
    return null;
  }

  return (
    <button
      className={`${className} ${shadow && 'shadow-xl'
        } pill px-spacing-20 h-spacing-48 py-spacing-14  
        flex items-center rounded-4xl 
        active:bg-neutral-fieldActive box-border ${disabled ? 'pointer-events-none' : 'pointer-events-auto'
        } ${checked
          ? `checked bg-base-white md:hover:border-interactive-backgroundStrongHover ${customBorderColor ? customBorderColor : 'border-interactive-backgroundStrong'}`
          : 'bg-neutral-field hover:bg-neutral-fieldHover'
        } ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={radio ? 'mr-spacing-10' : ''}>
        {radio && (
          <>
            <input
              type="checkbox"
              name={name}
              defaultChecked={checked}
              onChange={onChange}
              disabled={disabled}
              className="peer absolute invisible"
            />
            <label
              className={`radio-pill transition-all w-spacing-20 h-spacing-20 rounded-full cursor-pointer flex align-center items-center ${checked ? 'checked' : 'unchecked'
                } ${disabled ? 'disabled' : ''} ${icon ? 'has-icon' : ''}`}
              htmlFor={name}
            >
              <PillIcon />
            </label>
          </>
        )}
      </div>
      {title && (
        <div className="w-full" ref={ref}>
          <Typography
            variant="LabelDefault500"
            colorType={disabled ? 'neutral' : 'base'}
            color={disabled ? 'textDisabled' : 'gray800'}
            className={`flex-1 justify-center items-center text-left`}
            pointer={!disabled}
            clip={clip}
            divClassName={`${clip && ref.current && ref.current?.offsetWidth > 72
                ? 'w-spacing-80'
                : ''
              }`}
          >
            {title}
          </Typography>
        </div>
      )}
    </button>
  );
};

export default Pill;
