import AxiosClass from '.';
import env from '../env';
import {
  IGetProposalsPrice,
  IProposalItem,
  IProposalPrices,
} from '../screens/AppScreens/AdviceDetails/tabs/Proposals/interface';

class AdvisorService extends AxiosClass {
  async getAdvices() {
    return this.axiosInstance.get(`${env.APP_API}/advisor/advice-requests`);
  }

  async getAdvisorProfile() {
    return this.axiosInstance.get(`${env.APP_API}/advisor/profile`);
  }

  async getAdvisorAdviceChats(partnerId: string, adviceId: string) {
    return this.axiosInstance.get(
      `${env.APP_API}/advisor/chats/${partnerId}/${adviceId}`
    );
  }

  async getAdvisorAdviceDetails(adviceId: string) {
    return this.axiosInstance.get(
      `${env.APP_API}/advisor/advice-requests/${adviceId}`
    );
  }

  async getAdvisorChats(adviceId: string) {
    return this.axiosInstance.get(
      `${env.APP_API}/advisor/advices/${adviceId}/chats`
    );
  }

  async getProposalForAdvisor(adviceId: string) {
    return this.axiosInstance.get(
      `${env.APP_API}/advisor/advices/${adviceId}/proposals`
    );
  }

  async initiateAdvisorAccount(payload: {
    firstName: string;
    lastName: string;
    countries: string[];
    languages: string[];
    summary: string;
    picture: string;
  }) {
    return this.axiosInstance.post(`${env.APP_API}/advisor/profile`, payload);
  }

  async createProposals(adviceId: string) {
    return this.axiosInstance.post(
      `${env.APP_API}/advisor/advices/${adviceId}/proposals`
    );
  }

  async getProposalDetails(
    proposalId: string
  ): Promise<{ data: IProposalItem }> {
    return this.axiosInstance.get(
      `${env.APP_API}/advisor/proposals/${proposalId}`
    );
  }

  async putProposals(proposalId: string, body: IProposalItem) {
    return this.axiosInstance.put(
      `${env.APP_API}/advisor/proposals/${proposalId}`,
      { ...body }
    );
  }

  async submitProposals(proposalId: string, body: IProposalItem) {
    return this.axiosInstance.post(
      `${env.APP_API}/advisor/proposals/${proposalId}`,
      { ...body }
    );
  }

  async getProposalPrice(
    params: IGetProposalsPrice
  ): Promise<{ data: IProposalPrices }> {
    const { advicePairingId, ...rest } = params;

    const searchParams = new URLSearchParams({
      ...rest,
    }).toString();

    return this.axiosInstance.get(
      `${env.APP_API}/advisor/advice-pairings/${params?.advicePairingId}/proposal-price?${searchParams}`
    );
  }

  async declineCase({
    advicePairingId,
    reason,
  }: {
    advicePairingId: string;
    reason: string;
  }) {
    return this.axiosInstance.patch(
      `${env.APP_API}/advisor/advice-pairings/${advicePairingId}/decline`,
      { reason }
    );
  }

  async getPayments(): Promise<{
    data: { proposals: IProposalItem[]; partnerStatus: string };
  }> {
    return this.axiosInstance.get(`${env.APP_API}/advisor/proposals`);
  }
}

export default AdvisorService;
